<template>
  <div class="ele-body">
    <el-tabs>
      <el-card shadow="never">
        <!-- 搜索表单 -->
        <el-form :model="table.where" class="ele-form-search d-flex f-end"
                 @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
          <el-form-item label="省:" label-width="35px">
            <el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)"
                       placeholder='请选择省' class="input150" clearable>
              <el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
                         :label="option.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="市:" label-width="35px">
            <el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid),$forceUpdate()"
                       placeholder='请选择市' class="input150" clearable>
              <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
                         :label="option.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="县/区:" label-width="65px">
            <el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()"
                       class="input150" clearable>
              <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
                         :label="option.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="身份类型:" label-width="80px">
            <el-select v-model="table.where.type" placeholder="请选择" class="input150" clearable>
              <el-option label="用户" value="1"/>
              <el-option label="司机" value="2"/>
              <el-option label="跑腿" value="3"/>
              <el-option label="商家版-超市" value="4"/>
              <el-option label="商家版-叫餐" value="5"/>
            </el-select>
          </el-form-item>
          <el-form-item label="是否处理:" label-width="80px">
            <el-select v-model="table.where.status" placeholder="请选择" class="input150 mr-20" clearable>
              <el-option label="未处理" value="2"/>
              <el-option label="已处理" value="1"/>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="金额(元)：">
                    <el-input v-if="table.where.status==1" v-model="table.where.price1" class="inputWidth"></el-input>
                </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
                       class="ele-btn-icon">搜索
            </el-button>
            <el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
          </el-form-item>
        </el-form>
        <!-- 数据表格 -->
        <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
                        :stripe=true highlight-current-row>
          <template slot-scope="{index}">
            <el-table-column type="selection" width="45" align="center" fixed="left"/>
            <el-table-column type="index" :index="index" label="序号" width="60" align="center" fixed="left"
                             show-overflow-tooltip/>
            <el-table-column prop="create_time" label="日期" show-overflow-tooltip min-width="120"/>

            <el-table-column prop="type" label="身份" show-overflow-tooltip>
              <template slot-scope="scope">
                <!-- <span>{{scope.row.type==1?'用户':(scope.row.type==2?'司机':'跑腿')}}</span> -->
                <span v-if="scope.row.type==1">用户</span>
                <span v-if="scope.row.type==2">司机</span>
                <span v-if="scope.row.type==3">跑腿</span>
                <span v-if="scope.row.type==4">商家版-超市</span>
                <span v-if="scope.row.type==5">商家版-叫餐</span>
              </template>
            </el-table-column>
            <el-table-column prop="set_area" label="地区" show-overflow-tooltip min-width="120"/>
            <el-table-column prop="user_name" label="用户名" show-overflow-tooltip min-width="120"/>
            <el-table-column prop="tel" label="电话" show-overflow-tooltip min-width="120"/>
            <el-table-column prop="status" label="处理状态" show-overflow-tooltip>
              <template slot-scope="{row}">
                <span>{{ row.status == 0 ? '未处理' : row.status == 1 ? '已处理' : '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120px" align="center" :resizable="false">
              <template slot-scope="{row}">
                <el-link v-if="row.status == 1 && permission.includes('sys:service_feedback:view')" icon="el-icon-view"
                         type="primary" :underline="false"
                         @click="view(row)">查看
                </el-link>
                <el-link v-if="row.status != 1 && permission.includes('sys:service_feedback:edit')" icon="el-icon-edit"
                         type="primary" :underline="false"
                         @click="edit(row)">编辑
                </el-link>
              </template>
            </el-table-column>
          </template>
        </ele-data-table>

        <!-- 对话框：编辑反馈记录 -->
        <el-dialog v-dialogDrag title="编辑产品反馈记录" :visible.sync="dialogFormVisibleEditRecord"
                   @closed="[editForm={},$refs.table.reload()]"
                   :destroy-on-close="true" :lock-scroll="false">
          <el-form :model="editForm" label-width="100px">
            <el-card shadow="never">
              <el-form-item label="用户名:" class="drop-down-second">
                <el-input v-model="editForm.user_name" disabled></el-input>
              </el-form-item>
              <el-form-item label="电话:" class="drop-down-second">
                <el-input v-model="editForm.tel" disabled></el-input>
              </el-form-item>
              <el-form-item label="地区:" class="drop-down-second">
                <el-input v-model="editForm.set_area" disabled></el-input>
              </el-form-item>
              <el-form-item label="添加日期:" class="drop-down-second">
                <el-input v-model="editForm.create_time" disabled></el-input>
              </el-form-item>
              <el-form-item label="反馈内容:" class="drop-down-second">
                <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 4}"
                          v-model="editForm.content" disabled placeholder="反馈内容"></el-input>
              </el-form-item>
              <el-form-item label="图片:" class="drop-down-second aa d-flex" v-if="editForm.imgList!=0">
                <el-image v-for="(option, index) in editForm.imgList" :key="index"
                          style="width:100px;height:100px;margin-right:10px" :src="option" fit="cover"
                          :preview-src-list="editForm.imgList"></el-image>
              </el-form-item>
              <el-form-item label="处理结果:" class="drop-down-second">
                <el-radio-group v-model="editForm.status">
                  <el-radio :label="0">未处理</el-radio>
                  <el-radio :label="1">已处理</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="处理反馈：" prop="reply">
                <el-input v-model="editForm.reply" type="textarea" placeholder="请输入反馈内容" clearable class="w-400"/>
              </el-form-item>
            </el-card>
          </el-form>
          <div class="d-flex f-end" slot="footer">
            <el-button @click="dialogFormVisibleEditRecord=false">取消</el-button>
            <el-button type="primary" @click="save">确定提交</el-button>
          </div>
        </el-dialog>

        <!-- 对话框：查看反馈记录 -->
        <el-dialog v-dialogDrag title="查看产品反馈记录" :visible.sync="dialogFormVisibleViewRecord"
                   :destroy-on-close="true" :lock-scroll="false">
          <el-form :model="editForm" label-width="100px">
            <el-card shadow="never">
              <el-form-item label="用户名:" class="drop-down-second">
                <el-input v-model="editForm.user_name" disabled></el-input>
              </el-form-item>
              <el-form-item label="电话:" class="drop-down-second">
                <el-input v-model="editForm.tel" disabled></el-input>
              </el-form-item>
              <el-form-item label="地区:" class="drop-down-second">
                <el-input v-model="editForm.set_area" disabled></el-input>
              </el-form-item>
              <el-form-item label="添加日期:" class="drop-down-second">
                <el-input v-model="editForm.create_time" disabled></el-input>
              </el-form-item>
              <el-form-item label="反馈内容:" class="drop-down-second">
                <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 4}"
                          v-model="editForm.content" disabled placeholder="反馈内容"></el-input>
              </el-form-item>
              <el-form-item label="图片:" class="drop-down-second aa d-flex" v-if="editForm.imgList!=0">
                <el-image v-for="(option, index) in editForm.imgList" :key="index"
                          style="width:100px;height:100px;margin-right:10px" :src="option" fit="cover"
                          :preview-src-list="editForm.imgList"></el-image>
              </el-form-item>
              <el-form-item label="处理结果:" class="drop-down-second">
                <el-radio-group v-model="editForm.status" disabled>
                  <el-radio :label="0">未处理</el-radio>
                  <el-radio :label="1">已处理</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="处理反馈：" prop="reply">
                <el-input v-model="editForm.reply" disabled type="textarea" placeholder="请输入反馈内容" clearable
                          class="w-400"/>
              </el-form-item>
            </el-card>
          </el-form>
        </el-dialog>
      </el-card>
    </el-tabs>

  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'

export default {
  name: 'SysUser',

  data () {
    return {
      table: {
        url: '/customer/get_product_feedback',
        where: {}
      },
      choose: [], // 表格选中数据
      editForm: [], // 表单数据
      checkForm: {},
      provArr: {},
      cityArr: [],
      districtArr: [],
      urgentList: [],
      imageUrl: false,
      headImg: '',
      dialogFormVisibleAddActivity: false,
      dialogVisibleRecord: false,
      dialogFormVisibleViewRecord: false,
      dialogFormVisibleEditRecord: false,
      row: [],
      radioResult: '1'
    }
  },
  created () {
    this.$http.get('/common/province_list').then(res => {
      let data = JSON.parse(res.data)
      this.provArr = data
    })
  },
  computed: {
    ...mapGetters(['permission']),
  },
  mounted () {

  },
  methods: {
    /**
     *选择省
     **/
    handleChangeProv (e) {
      /** 获取被选省份的pid**/
      let pid = ''
      this.provArr.forEach(function (item) {
        if (item.pid == e) {
          pid = item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list', {
        pid: pid
      }).then(res => {
        let data = JSON.parse(res.data)
        this.cityArr = data
        /** 选择省份清空市县**/
        this.table.where.cid = ''
        this.table.where.aid = ''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity (e) {
      if (e) {
        /** 获取被选市的cid**/
        let cid = ''
        this.cityArr.forEach(function (item) {
          if (item.cid == e) {
            cid = item.cid
          }
        })
        /** 根据被选市的cid获取市下面的县**/
        this.$http.post('/common/area_list', {
          cid: cid
        }).then(res => {
          let data = JSON.parse(res.data)
          this.districtArr = data
          /** 选择省份清空县**/
          this.table.where.aid = ''
        })
      } else {
        this.table.where.pid = ''
        this.table.where.aid = ''
      }
    },
    //编辑弹窗
    edit (row) {
      this.dialogFormVisibleEditRecord = true
      // this.radio = row.status
      row.imgList = row.image.split(',')
      this.editForm = row
    },
    //查看弹窗
    view (row) {
      this.dialogFormVisibleViewRecord = true
      // this.radio = row.status
      row.imgList = row.image.split(',')
      this.editForm = row
    },
    //处理投诉
    save () {
      if (this.editForm.status == 0) {
        return this.$message.error('请选择已处理')
      }
      // if(this.editForm.status==1){
      const loading = this.$loading({
        lock: true
      })
      this.$http.post('/customer/deal_with_feedback', {
        id: this.editForm.id,
        status: this.editForm.status,
        reply: this.editForm.reply
      }).then(res => {
        loading.close()
        if (res.data.code == 0) {
          this.dialogFormVisibleEditRecord = false
          this.$refs.table.reload()
          this.$message({
            type: 'success',
            message: res.data.msg
          })
        } else {
          this.$message.error(res.data.msg)
          // this.$message({
          //   type: 'success',
          //   message: res.data.msg
          // })
        }
      }).catch(e => {
        loading.close()
        this.$message.error(e.message)
      })
    },
  },
}
</script>

<style scoped>
.ele-block .el-upload-dragger {
  width: 100%;
}

.changeStyle {
  width: 150px;
  margin-right: 30px;
}

.ele-body {
  padding: 15px;
}

.el-upload-list el-upload-list--text {
  display: none !important
}

.user-info-tabs >>> .el-tabs__nav-wrap {
  padding-left: 20px;
}

.aa /deep/ .el-form-item__content {
  display: block;
  width: 100%;
}

.aa /deep/ .el-form-item__content .el-image {
  width: 90px !important;
  height: 90px !important;
}

/deep/ .el-dialog {
  margin-top: 30px !important;
}
</style>
